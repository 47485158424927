import { useEffect } from 'react';

export const useBodyClass = (className: string): void => {
  useEffect(() => {
    // Add the class to the body tag when the component mounts
    document.body.classList.add(className);

    // Clean up: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove(className);
    };
  }, [className]); // Ensure the effect re-runs only if className changes
};
